<div class="content py-10">
  <h2 class="mb-10">Bemutatkozó szöveg</h2>
  <form class="flex flex-col gap-6">
    <div class="flex gap-6">
      <div class="flex flex-col gap-6 flex-auto">
        <fokos-input>
          <span fokosInputTitle>cím</span>
          <input [formControl]="form.controls.title" placeholder="..." />
        </fokos-input>

        <fokos-input [paddingOff]="true">
          <span fokosInputTitle>szöveg</span>
          <ckeditor [formControl]="form.controls.description" [editor]="Editor"></ckeditor>
        </fokos-input>
        <fokos-input [paddingOff]="true">
          <span fokosInputTitle>lenyitható szöveg</span>
          <ckeditor [formControl]="form.controls.moreDescription" [editor]="Editor"></ckeditor>
        </fokos-input>
      </div>
      <div class="flex flex-col gap-6 flex-1">
        <div class="flex-1 flex flex-col gap-6 items-start">
          <div class="w-[300px] min-h-[300px] border rounded-md flex items-center justify-center">
            <img *ngIf="form.controls.image.value; else placeholder"
                 class="w-full h-auto rounded-md"
                 [src]="form.controls.image.value"
            >
            <ng-template #placeholder>placeholder</ng-template>
          </div>
          <fokos-button (click)="uploadAndResize()">Új kép feltöltése</fokos-button>
        </div>
      </div>
    </div>
    <fokos-button type="submit" (click)="save()">Mentés</fokos-button>
  </form>
</div>
