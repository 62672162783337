import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CouponDto } from "@frontend/data-access";
import { AdminService } from "../../services/admin.service";
import { ConfirmModalComponent, ConfirmModalInput, ModalService } from "@frontend/shared";

@Component({
  selector: 'fokos-coupons',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.scss'],
})
export class CouponsComponent implements OnInit {
  form = new FormGroup({
    code: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(20)]),
    discount: new FormControl<null | number>(null,[ Validators.required, Validators.min(1)]),
  });
  coupons: CouponDto[] = [];

  constructor(private service: AdminService,
              private modalService: ModalService) {
  }

  ngOnInit() {
    this.service.getCoupons().subscribe(res => this.coupons = res);
  }

  save() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.service.saveCoupon(this.form.value as CouponDto).subscribe(
        res => {
          this.coupons.push(res);
          this.form.reset();
        }
      );
    }
  }

  delete(coupon: CouponDto) {
    this.modalService.open<ConfirmModalComponent, ConfirmModalInput, boolean>(ConfirmModalComponent, {
      message: `Biztosan törlöd a ${coupon.code} nevű kupont?`
    }).subscribe(res => {
      if (res) {
        this.service.deleteCoupon(coupon).subscribe(
          res =>
            this.coupons = this.coupons.filter(c => c.code !== res.code)
        );
      }
    });


  }
}
