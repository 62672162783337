import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { GreetingDto } from "@frontend/data-access";
import { AdminService } from "../../services/admin.service";
// @ts-ignore
import Editor from "ckeditor5-custom-build/build/ckeditor";

@Component({
  selector: 'fokos-greeting',
  templateUrl: './greeting.component.html',
  styleUrls: ['./greeting.component.scss'],
})
export class GreetingComponent implements OnInit {
  public Editor = Editor;

  form: FormGroup<Record<keyof GreetingDto, FormControl>> = new FormGroup({
      title: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
    }
  );

  constructor(private service: AdminService) {
  }

  ngOnInit() {
    this.service.getGreeting().subscribe(res => this.form.patchValue(res));
  }

  save() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.service.saveGreeting(this.form.value as GreetingDto).subscribe();
    }
  }
}

