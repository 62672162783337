import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from "./environments/environment";
import { enableProdMode } from "@angular/core";
import { persistState } from "@datorama/akita";

if (environment.production) {
  enableProdMode();
}
const storage = persistState();

const providers = [{provide: 'persistStorage', useValue: storage}];

platformBrowserDynamic(providers)
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
