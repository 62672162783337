import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import {
  ButtonModule,
  CheckboxModule,
  CurrencyPipe,
  DeliveryModePipe,
  ErrorInterceptor,
  FooterModule,
  InputModule,
  ModalService,
  ModalsModule,
  NumberPipe,
  NumberStepperModule,
  PayModePipe,
  RadioModule,
  SelectModule,
  ToolbarModule,
  ZigZagModule,
} from '@frontend/shared';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './pages/login/login.component';
import { GreetingComponent } from './pages/greeting/greeting.component';
import { AboutComponent } from './pages/about/about.component';
import { GinDetailsComponent } from './pages/gin-details/gin-details.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TableModule } from 'ngx-easy-table';
import { CouponsComponent } from './pages/coupons/coupons.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { PipesModule } from '@frontend/shared';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { EditOrderComponent } from './pages/orders/edit/edit-order/edit-order.component';
import { DecimalPipe } from '@angular/common';
import { SubscriptionsComponent } from './pages/subscriptions/subscriptions.component';
import { ApiInterceptor } from "./interceptors/api.interceptor";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    GreetingComponent,
    AboutComponent,
    GinDetailsComponent,
    OrdersComponent,
    CouponsComponent,
    EditOrderComponent,
    SubscriptionsComponent,
  ],
  imports: [
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ButtonModule,
    ToolbarModule,
    FooterModule,
    ZigZagModule,
    InputModule,
    CheckboxModule,
    ReactiveFormsModule,
    SelectModule,
    NumberStepperModule,
    FormsModule,
    TableModule,
    CKEditorModule,
    ModalsModule,
    PipesModule,
    RadioModule,
  ],
  providers: [
    ModalService,
    PayModePipe,
    DeliveryModePipe,
    DecimalPipe,
    NumberPipe,
    CurrencyPipe,
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
