import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { GinDto } from "@frontend/data-access";
import { AdminService } from "../../services/admin.service";
import { DataUrl, NgxImageCompressService, UploadResponse } from "ngx-image-compress";
// @ts-ignore
import Editor from "ckeditor5-custom-build/build/ckeditor";

@Component({
  selector: 'fokos-gin-details',
  templateUrl: './gin-details.component.html',
  styleUrls: ['./gin-details.component.scss'],
})
export class GinDetailsComponent implements OnInit {
  Editor = Editor;

  form = new FormGroup({
      name: new FormControl('', Validators.required),
      alcohol: new FormControl<number | null>(null, Validators.required),
      capacity: new FormControl<number | null>(null, Validators.required),
      price: new FormControl<number | null>(null, Validators.required),
      description: new FormControl('', Validators.required),
      storageInstruction: new FormControl('', Validators.required),
      shippingInformation: new FormControl('', Validators.required),
      image: new FormControl(''),
    }
  );

  constructor(private service: AdminService,
              private imageCompress: NgxImageCompressService) {

  }

  ngOnInit() {
    this.service.getGin().subscribe(res => this.form.patchValue(res));
  }

  save() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.service.saveGin(this.form.value as GinDto).subscribe();
    }
  }

  uploadAndResize() {
    return this.imageCompress
      .uploadFile()
      .then(({ image }: UploadResponse) => {
        this.form.controls.image.setValue(image);
      });
  }
}
