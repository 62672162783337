<div class="content py-6">
  <h2 class="mb-10">Gin adatai</h2>
  <div class="flex gap-6 mb-10">
    <div class="flex-2 flex flex-col gap-6">
      <fokos-input>
        <span fokosInputTitle>név</span>
        <input [formControl]="form.controls.name" placeholder="..."/>
        <span fokosCounter>{{form.controls.name.value?.length}}/255</span>
      </fokos-input>
      <div class="flex gap-6">
        <fokos-input class="flex-1">
          <span fokosInputTitle>űrtartalom</span>
          <input [formControl]="form.controls.capacity" [min]="0.01" placeholder="0.7"/>
          <span fokosInputSuffix>l</span>
        </fokos-input>
        <fokos-input class="flex-1">
          <span fokosInputTitle>alkoholtartalom</span>
          <input [formControl]="form.controls.alcohol" [min]="0" placeholder="40"/>
          <span fokosInputSuffix>%</span>
        </fokos-input>
      </div>
      <fokos-input>
        <span fokosInputTitle>ár</span>
        <input [formControl]="form.controls.price" [min]="0.01" placeholder="..."/>
        <span fokosInputSuffix>Ft.</span>
      </fokos-input>
      <fokos-input [paddingOff]="true">
        <span fokosInputTitle>leírás</span>
        <ckeditor [formControl]="form.controls.description" [editor]="Editor"></ckeditor>
      </fokos-input>
      <fokos-input>
        <span fokosInputTitle>tárolás</span>
        <textarea [formControl]="form.controls.storageInstruction" placeholder="..."></textarea>
      </fokos-input>
      <fokos-input>
        <span fokosInputTitle>szállítás</span>
        <textarea [formControl]="form.controls.shippingInformation" placeholder="..."></textarea>
      </fokos-input>
    </div>
    <div class="flex-1 flex flex-col gap-6 items-start">
      <div class="w-[300px] min-h-[300px] border rounded-md flex items-center justify-center">
        <img *ngIf="form.controls.image.value; else placeholder"
             class="w-full h-auto rounded-md"
             [src]="form.controls.image.value"
        >
        <ng-template #placeholder>placeholder</ng-template>
      </div>
      <fokos-button (click)="uploadAndResize()">Új kép feltöltése</fokos-button>
    </div>
  </div>
  <fokos-button type="submit" (click)="save()">Mentés</fokos-button>
</div>
