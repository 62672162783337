import { Query } from '@datorama/akita';
import { Injectable } from "@angular/core";
import { SessionState, SessionStore } from "./session.state";

@Injectable({providedIn: 'root'})
export class SessionQuery extends Query<SessionState> {
  constructor(store: SessionStore) {
    super(store);
  }

  isLoggedIn$ = this.select(state => !!state.token);

  get token() {
    return this.getValue().token;
  }
}

