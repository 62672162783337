<div class="content pt-20">
  <h1 class="text-center mb-10">Bejelentkezés</h1>
  <form class="flex justify-center">
    <div class="flex flex-col gap-6 w-[400px]">
      <div class="text-primary h-4">{{error ?? ''}}</div>
      <fokos-input class="w-[400px]">
        <span fokosInputTitle>email</span>
        <input [formControl]="form.controls.email" type="email" placeholder="example@example.com"/>
      </fokos-input>
      <fokos-input class="w-[400px]">
        <span fokosInputTitle>jelszó</span>
        <input [formControl]="form.controls.password" type="password" placeholder="*********"/>
      </fokos-input>
      <fokos-button (click)="login()">Bejelentkezés</fokos-button>
    </div>
  </form>
</div>
