<div class="content pt-6">
  <h2 class="mb-10">Megrendelések</h2>

  <ngx-table
    [configuration]="configuration"
    [data]="dataSource"
    [columns]="columnsDef"
    [detailsTemplate]="detailsTemplate"
    [pagination]="pagination"
    (event)="eventEmitted($event)"
  >
    <ng-template let-row>
      <td>{{ row.id }}</td>
      <td>{{ row.shippingData.name }}</td>
      <td>{{ row.shippingData.delivery.deliveryMode | fokosDeliveryMode}}</td>
      <td>{{ row.shippingData.address }}</td>
      <td>{{ row.totalPrice | fokosCurrency }}</td>
      <td>{{ row.payMode | fokosPayMode }}</td>
      <td>{{ row.status | fokosStatus }}</td>
      <td>
        <div class="flex gap-4" *ngIf="row.status !== OrderStatus.Deleted">
          <img title="szerkesztés" (click)="openEdit(row)" class="w-6 min-w-[1.5rem] cursor-pointer"
               src="assets/icons/edit.svg">
          <img title="törlés" (click)="delete(row)" class="w-6 min-w-[1.5rem] cursor-pointer"
               src="assets/icons/delete.svg">
        </div>
      </td>
    </ng-template>
  </ngx-table>
</div>

<ng-template #detailsTemplate let-row>
  <div class="flex gap-4">
    <div class="flex-1 grid grid-cols-2 gap-x-4 gap-y-2 px-8 h-fit">
      <b class="col-span-2">Számlázási adatok</b>
      <p>név:</p><span>{{row.invoiceData.name}}</span>
      <ng-container *ngIf="row.invoiceData.company">
        <p>cég:</p><span>{{row.invoiceData.company}}</span>
      </ng-container>
      <ng-container *ngIf="row.invoiceData.taxNumber">
        <p>adószám:</p><span>{{row.invoiceData.taxNumber}}</span>
      </ng-container>
      <p>irsz.:</p><span>{{row.invoiceData.zip}}</span>
      <p>város:</p><span>{{row.invoiceData.city}}</span>
      <p>cím:</p><span>{{row.invoiceData.address}}</span>
      <p>email:</p><span>{{row.invoiceData.email}}</span>
      <p>telefonszám:</p><span>{{row.invoiceData.phone}}</span>
    </div>
    <div class="flex-1 grid grid-cols-2 gap-x-4 gap-y-2 px-8 h-fit">
      <b class="col-span-2">Szállítási adatok</b>
      <p>név:</p><span>{{row.shippingData.name}}</span>
      <p>cím:</p><span>{{row.shippingData.address}}</span>
      <p>email:</p><span>{{row.shippingData.email}}</span>
      <p>telefonszám:</p><span>{{row.shippingData.phone}}</span>
      <p>átvételi mód:</p><span>{{row.shippingData.delivery.deliveryMode| fokosDeliveryMode}}</span>
    </div>
    <div class="flex-1 grid grid-cols-2 gap-x-4 gap-y-2 px-8 h-fit">
      <b class="col-span-2">Rendelési adatok</b>
      <p>összeg:</p><span>{{row.totalPrice | fokosCurrency}}</span>
      <p>fizetési mód:</p><span>{{row.payMode| fokosPayMode}}</span>
      <p>dátum:</p><span>{{row.date | date:'y. MM. d. HH:mm:ss'}}</span>
      <p>státusz:</p><span>{{row.status | fokosStatus}}</span>
      <ng-container *ngIf="row.coupon">
        <p>kupon:</p><span>{{row.coupon.code}}</span>
        <p>kedvezmény:</p><span>{{row.coupon.discount |fokosCurrency}}</span>
      </ng-container>
      <p>tranzakció:</p><span>{{row.transactionId}}</span>
      <b class="col-span-2">Kosár</b>
      <ng-container *ngFor="let item of row.entities">
        <p>{{item.gin.name}}:</p><span>{{item.count}} db</span>
      </ng-container>
    </div>
  </div>
</ng-template>
