import { Component, OnInit, ViewContainerRef } from "@angular/core";
import { akitaConfig, akitaDevtools, enableAkitaProdMode } from "@datorama/akita";
import { environment } from "../environments/environment";
import { SessionQuery } from "./state/session/session.query";
import { SessionService } from "./services/session.service";
import { LoggerService, ModalService } from "@frontend/shared";

@Component({
  selector: "fokos-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  title = "fokos-admin";

  constructor(
    private viewContainer: ViewContainerRef,
    private modalService: ModalService,
    public sessionQuery: SessionQuery,
    private sessionService: SessionService
  ) {
    LoggerService.PRODUCTION = environment.production;
    this.modalService.setViewContainerRef(this.viewContainer);
  }

  ngOnInit() {
    akitaConfig({ resettable: true });
    if (environment.production) {
      enableAkitaProdMode();
    } else {
      akitaDevtools();
    }
  }

  async logout() {
    this.sessionService.logout();
  }
}
