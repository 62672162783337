<div class="content py-10">
  <h2 class="mb-10">Kuponok</h2>
  <div class="flex flex-row gap-10">
    <form class="flex-1 flex flex-col gap-6">
      <b>új kupon</b>
      <fokos-input [error]="form.controls.code.invalid && form.controls.code.touched">
        <span fokosInputTitle>kód</span>
        <input [formControl]="form.controls.code" placeholder="FOKOS2023"/>
        <span *ngIf="form.controls.code.hasError('minlength') && form.controls.code.touched" fokosError>Minimum 5 karakter</span>
        <span *ngIf="form.controls.code.hasError('maxlength') && form.controls.code.touched" fokosError>Maximum 20 karakter</span>
      </fokos-input>
      <fokos-input [error]="form.controls.discount.invalid && form.controls.discount.touched">
        <span fokosInputTitle>kedvezmény</span>
        <input [formControl]="form.controls.discount" placeholder="1000" type="number"/>
        <span fokosInputSuffix>Ft</span> <span
        *ngIf="form.controls.discount.hasError('min') && form.controls.discount.touched"
        fokosError>Minimum 1 Ft</span>
      </fokos-input>
      <fokos-button type="submit" (click)="save()">Mentés</fokos-button>
    </form>
    <div class="flex-3 flex flex-col gap-2">
      <div class="grid grid-cols-3-auto items-center gap-x-4 gap-y-2 text-center">
        <b>Kód</b>
        <b>Kedvezmény</b>
        <b>Törlés</b>
        <ng-container *ngFor="let coupon of coupons">
          <p>{{coupon.code}}</p>
          <p>{{coupon.discount | fokosCurrency}}</p>
          <div class="justify-self-center">
            <svg
              (click)="delete(coupon)" class="cursor-pointer hover:fill-primary"
              width="32" height="32" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M18.05 33.05L24 27L30 33.05L32.35 30.65L26.4 24.6L32.35 18.55L30 16.15L24 22.2L18.05 16.15L15.65 18.55L21.65 24.6L15.65 30.65L18.05 33.05ZM13.05 42C12.25 42 11.55 41.7 10.95 41.1C10.35 40.5 10.05 39.8 10.05 39V10.5H8V7.5H17.4V6H30.6V7.5H40V10.5H37.95V39C37.95 39.8 37.65 40.5 37.05 41.1C36.45 41.7 35.75 42 34.95 42H13.05ZM34.95 10.5H13.05V39H34.95V10.5Z"/>
            </svg>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
