import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { SessionQuery } from "../state/session/session.query";
import { firstValueFrom } from "rxjs";
import { LoggerService } from "@frontend/shared";

@Injectable({
  providedIn: "root"
})
export class LoggedOutGuard implements CanActivate {
  constructor(private router: Router,
              private sessionQuery: SessionQuery) {
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    const isLoggedIn = await firstValueFrom(this.sessionQuery.isLoggedIn$);
    LoggerService.log("isLoggedIn", isLoggedIn);
    if (isLoggedIn) {
      this.router.navigate(["/megrendelesek"]);
      return false;
    }
    return true;
  }

}
