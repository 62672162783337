import { RouterModule, Routes } from '@angular/router';
import { NgModule } from "@angular/core";
import { GreetingComponent } from "./pages/greeting/greeting.component";
import { OrdersComponent } from "./pages/orders/orders.component";
import { AboutComponent } from "./pages/about/about.component";
import { GinDetailsComponent } from "./pages/gin-details/gin-details.component";
import { LoginComponent } from "./pages/login/login.component";
import { AuthGuard } from "./guards/auth.guard";
import { LoggedOutGuard } from "./guards/logged-out.guard";
import { CouponsComponent } from "./pages/coupons/coupons.component";
import { SubscriptionsComponent } from "./pages/subscriptions/subscriptions.component";

const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: 'megrendelesek'},
  {path: 'bejelentkezes', component: LoginComponent, canActivate: [LoggedOutGuard]},
  {path: 'udvozlo', component: GreetingComponent, canActivate: [AuthGuard]},
  {path: 'bemutatkozo', component: AboutComponent, canActivate: [AuthGuard]},
  {path: 'gin', component: GinDetailsComponent, canActivate: [AuthGuard]},
  {path: 'megrendelesek', component: OrdersComponent, canActivate: [AuthGuard]},
  {path: 'feliratkozasok', component: SubscriptionsComponent, canActivate: [AuthGuard]},
  {path: 'kuponok', component: CouponsComponent, canActivate: [AuthGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
