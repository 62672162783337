<div class="content pt-6">
  <h2 class="mb-10">Feliratkozások</h2>
  <div class="grid grid-cols-2 gap-4">
    <a class="no-style self-start mb-4" href="mailto:?bcc={{emails}}">
      <fokos-button>Email küldése mindenkinek</fokos-button>
    </a>
    <div></div>

    <span class="text-green font-bold">név</span>
    <span class="text-green font-bold">email</span>
    <ng-container *ngFor="let item of subscriptions">
      <span class="text-sm">{{item.name}}</span>
      <a class="text-sm text-primary hover:text-primary-dark" href="mailto:{{item.email}}?body={{item.name}}">{{item.email}}</a>
    </ng-container>
  </div>
</div>
