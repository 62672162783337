import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { SessionQuery } from "../state/session/session.query";
import { firstValueFrom } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router,
              private sessionQuery: SessionQuery) {
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    const isLoggedIn = await firstValueFrom(this.sessionQuery.isLoggedIn$);
    if (!isLoggedIn) {
      this.router.navigate(['/bejelentkezes']);
      return false;
    }
    return true;
  }

}
