<fokos-toolbar #toolbarComponent>
  <div desktopItems class="gap-10 text-button font-bold items-center hidden palmtop:flex">
    <ng-container *ngIf="sessionQuery.isLoggedIn$ | async">
      <fokos-toolbar-item routerLink="/udvozlo">Üdvözlő</fokos-toolbar-item>
      <fokos-toolbar-item routerLink="/bemutatkozo">Bemutatkozó</fokos-toolbar-item>
      <fokos-toolbar-item routerLink="/gin">Gin adatai</fokos-toolbar-item>
      <fokos-toolbar-item routerLink="/kuponok">Kuponok</fokos-toolbar-item>
      <fokos-toolbar-item routerLink="/feliratkozasok">Feliratkozások</fokos-toolbar-item>
      <fokos-toolbar-item routerLink="/megrendelesek">Megrendelések</fokos-toolbar-item>
      <fokos-toolbar-item routerLink="/" (click)="logout()">
        <img class="w-6 min-w-[1.5rem]" src="assets/icons/logout.svg"/>
      </fokos-toolbar-item>
    </ng-container>
  </div>
  <div mobileItems class="flex flex-col items-start gap-6 font-semibold">
    <ng-container *ngIf="sessionQuery.isLoggedIn$ | async">
      <fokos-toolbar-item (click)="toolbarComponent.toggleMenu()" routerLink="/udvozlo">Üdvözlő</fokos-toolbar-item>
      <fokos-toolbar-item (click)="toolbarComponent.toggleMenu()" routerLink="/bemutatkozo">Bemutatkozó</fokos-toolbar-item>
      <fokos-toolbar-item (click)="toolbarComponent.toggleMenu()" routerLink="/gin">Gin adatai</fokos-toolbar-item>
      <fokos-toolbar-item (click)="toolbarComponent.toggleMenu()" routerLink="/kuponok">Kuponok</fokos-toolbar-item>
      <fokos-toolbar-item (click)="toolbarComponent.toggleMenu()" routerLink="/feliratkozasok">Feliratkozások</fokos-toolbar-item>
      <fokos-toolbar-item (click)="toolbarComponent.toggleMenu()" routerLink="/megrendelesek">Megrendelések</fokos-toolbar-item>
      <fokos-toolbar-item routerLink="/" (click)="logout()">Kijelentkezés</fokos-toolbar-item>
    </ng-container>
  </div>
</fokos-toolbar>
<div class="routing">
  <router-outlet></router-outlet>
</div>


