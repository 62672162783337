import { Component } from "@angular/core";
import { Modal } from "@frontend/shared";
import { DeliveryMode, DeliveryModes, OrderEntityDto, PayMode, PlacedOrderDto } from "@frontend/data-access";
import { AbstractControl, FormControl, FormGroup, UntypedFormArray, Validators } from "@angular/forms";
import { AdminService } from "../../../../services/admin.service";

@Component({
  selector: "fokos-edit-order",
  templateUrl: "./edit-order.component.html",
  styleUrls: ["./edit-order.component.scss"]
})
export class EditOrderComponent extends Modal<PlacedOrderDto, boolean> {
  PayMode = PayMode;
  DeliveryMode = DeliveryMode;
  DeliveryModes = DeliveryModes;
  order: PlacedOrderDto;
  formGroup = new FormGroup({
    invoiceData: new FormGroup({
      name: new FormControl("", { validators: Validators.required, nonNullable: true }),
      company: new FormControl<string | null>(null),
      taxNumber: new FormControl<string | null>(null),
      zip: new FormControl("", { validators: Validators.required, nonNullable: true }),
      city: new FormControl("", { validators: Validators.required, nonNullable: true }),
      address: new FormControl("", { validators: Validators.required, nonNullable: true }),
      phone: new FormControl("", { validators: Validators.required, nonNullable: true }),
      email: new FormControl("", [Validators.required, Validators.email]),
      comment: new FormControl<string | null>(null)
    }),
    shippingData: new FormGroup({
      name: new FormControl("", { validators: Validators.required, nonNullable: true }),
      address: new FormControl("", { validators: Validators.required, nonNullable: true }),
      phone: new FormControl("", { validators: Validators.required, nonNullable: true }),
      email: new FormControl("", [Validators.required, Validators.email]),
      comment: new FormControl<string | null>(null),
      delivery: new FormControl(DeliveryModes[3], { validators: Validators.required, nonNullable: true })
    }),
    entities: new UntypedFormArray([])
  });

  constructor(private service: AdminService) {
    super();
  }

  get invoiceControls() {
    return this.formGroup.controls.invoiceData.controls;
  }

  get shippingControls() {
    return this.formGroup.controls.shippingData.controls;
  }

  onInjectInputs(input: PlacedOrderDto): void {
    this.order = input;
    this.formGroup.patchValue(input);
    input.entities.forEach(e => {
      this.formGroup.controls.entities.push(new FormGroup({
        id: new FormControl<string>(e.id),
        count: new FormControl<number>(e.count),
        gin: new FormControl<any>(e.gin)
      }));
    });
    this.shippingControls.delivery.patchValue(DeliveryModes.find(m => m.deliveryMode === input.shippingData.delivery.deliveryMode) ?? DeliveryModes[3]);
  }

  save() {
    const dto = { ...this.order, ...this.formGroup.value, totalPrice: this.totalPrice } as PlacedOrderDto;
    this.service.updateOrder(dto).subscribe(
      res => this.close(true)
    );
  }

  get totalPrice() {
    const sum = this.formGroup.controls.entities.value.map((e: OrderEntityDto) => e.count * e.gin.price).reduce((a: number, b: number) => a + b);
    const discount = this.order.coupon?.discount ?? 0;
    const total = sum > discount ? sum - discount : 0;
    return total + this.shippingControls.delivery.value.price;
  }

  asFormGroup(control: AbstractControl) {
    return control as FormGroup;
  }


}
