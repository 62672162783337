import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { OrderStatus, PlacedOrderDto } from "@frontend/data-access";
import { DefaultConfig } from "ngx-easy-table";
import { Config } from "ngx-easy-table/lib/model/config";
import { Subject, takeUntil } from "rxjs";
import { AdminService } from "../../services/admin.service";
import { ConfirmModalComponent, ConfirmModalInput, CurrencyPipe, LoggerService, ModalService } from "@frontend/shared";
import { EditOrderComponent } from "./edit/edit-order/edit-order.component";

interface EventObject {
  event: string;
  value: {
    limit: number;
    page: number;
  };
}

@Component({
  selector: "fokos-orders",
  templateUrl: "./orders.component.html",
  styleUrls: ["./orders.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrdersComponent implements OnInit {
  OrderStatus = OrderStatus;
  configuration: Config;
  pagination = {
    limit: 10,
    offset: 1,
    count: -1
  };

  columnsDef = [
    { key: "id", title: "id", width: "5%" },
    { key: "name", title: "megrendelő", width: "15%" },
    { key: "delivery", title: "átvétel", width: "auto" },
    { key: "address", title: "szállítási cím", width: "20%" },
    { key: "totalPrice", title: "összeg", width: "auto" },
    { key: "payMode", title: "fizetési mód", width: "auto" },
    { key: "status", title: "státusz", width: "auto" }
  ];
  dataSource: PlacedOrderDto[] = [];

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private readonly cdr: ChangeDetectorRef,
              private readonly modalService: ModalService,
              private readonly currencyPipe: CurrencyPipe,
              private readonly service: AdminService) {
  }

  ngOnInit() {
    this.configuration = { ...DefaultConfig };
    this.configuration.showDetailsArrow = true;
    this.configuration.detailsTemplate = true;
    this.configuration.threeWaySort = true;
    this.getData();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  skipEvents = [
    "onRowCollapsedHide",
    "onRowCollapsedShow",
    "onClick",
    "onDoubleClick"
  ];

  eventEmitted($event: { event: string; value: any }): void {
    LoggerService.log($event);
    if (!this.skipEvents.includes($event.event)) {
      this.parseEvent($event);
    }
  }

  private parseEvent(obj: EventObject): void {
    this.pagination.limit = obj.value.limit ? obj.value.limit : this.pagination.limit;
    this.pagination.offset = obj.value.page ? obj.value.page : this.pagination.offset;
    this.pagination = { ...this.pagination };
    this.getData();
  }

  private getData(): void {
    this.configuration.isLoading = true;
    this.service
      .getOrders(this.pagination.offset - 1, this.pagination.limit)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        this.dataSource = response.content;
        // ensure this.pagination.count is set only once and contains count of the whole array, not just paginated one
        this.pagination.count =
          this.pagination.count === -1
            ? response
              ? response.totalElements
              : 0
            : this.pagination.count;
        this.pagination = { ...this.pagination };
        this.configuration.isLoading = false;
        this.cdr.detectChanges();
      });
  }

  openEdit(order: PlacedOrderDto) {
    this.modalService.open<EditOrderComponent, PlacedOrderDto, boolean>(EditOrderComponent, order).subscribe(
      res => res && this.getData()
    );
  }

  delete(order: PlacedOrderDto) {
    this.modalService.open<ConfirmModalComponent, ConfirmModalInput, boolean>(ConfirmModalComponent,
      { message: `Biztosan törlöd a(z) ${order.invoiceData.name} által leadott ${this.currencyPipe.transform(order.totalPrice)} értékű rendelést?` })
      .subscribe(res => {
        res && this.service.deleteOrder(order).subscribe(res => this.getData());
      });
  }
}
