import { Injectable } from "@angular/core";
import { AboutDto, CouponDto, GinDto, GreetingDto, PaginatedResponse, PlacedOrderDto, Response, SubscriptionDto } from "@frontend/data-access";
import { HttpClient } from "@angular/common/http";
import { catchError, map, Observable, shareReplay, tap } from "rxjs";
import { ModalService } from "@frontend/shared";

@Injectable({ providedIn: "root" })
export class AdminService {

  constructor(private http: HttpClient,
              private modalService: ModalService) {
  }

  getGreeting(): Observable<GreetingDto> {
    const modalRef = this.modalService.openLoadingModal();
    return this.http.get<Response<GreetingDto>>("api/greeting").pipe(
      tap(() => modalRef.close()),
      map(r => r.result),
      catchError(e => {
        modalRef.close();
        throw e;
      }),
      shareReplay()
    );
  }

  saveGreeting(dto: GreetingDto): Observable<GreetingDto> {
    const modalRef = this.modalService.openLoadingModal();
    return this.http.post<Response<GreetingDto>>("api/greeting", dto).pipe(
      tap(() => modalRef.close()),
      map(res => res.result),
      catchError(e => {
        modalRef.close();
        throw e;
      }),
      shareReplay()
    );
  }

  getAbout(): Observable<AboutDto> {
    const modalRef = this.modalService.openLoadingModal();
    return this.http.get<Response<AboutDto>>("api/about").pipe(
      tap(() => modalRef.close()),
      map(r => r.result),
      catchError(e => {
        modalRef.close();
        throw e;
      }),
      shareReplay()
    );
  }

  saveAbout(dto: AboutDto): Observable<AboutDto> {
    const modalRef = this.modalService.openLoadingModal();
    return this.http.post<Response<AboutDto>>("api/about", dto).pipe(
      tap(() => modalRef.close()),
      map(res => res.result),
      catchError(e => {
        modalRef.close();
        throw e;
      }),
      shareReplay()
    );
  }

  getGin(): Observable<GinDto> {
    const modalRef = this.modalService.openLoadingModal();
    return this.http.get<Response<GinDto>>("api/gin").pipe(
      tap(() => modalRef.close()),
      map(r => r.result),
      catchError(e => {
        modalRef.close();
        throw e;
      }),
      shareReplay()
    );
  }

  saveGin(dto: GinDto): Observable<GinDto> {
    const modalRef = this.modalService.openLoadingModal();
    return this.http.post<Response<GinDto>>("api/gin", dto).pipe(
      tap(() => modalRef.close()),
      map(res => res.result),
      catchError(e => {
        modalRef.close();
        throw e;
      }),
      shareReplay()
    );
  }

  getCoupons(): Observable<CouponDto[]> {
    const modalRef = this.modalService.openLoadingModal();
    return this.http.get<Response<CouponDto[]>>("api/coupon").pipe(
      tap(() => modalRef.close()),
      map(r => r.result),
      catchError(e => {
        modalRef.close();
        throw e;
      }),
      shareReplay()
    );
  }

  saveCoupon(dto: CouponDto): Observable<CouponDto> {
    const modalRef = this.modalService.openLoadingModal();
    return this.http.post<Response<CouponDto>>("api/coupon", dto).pipe(
      tap(() => modalRef.close()),
      map(res => res.result),
      catchError(e => {
        modalRef.close();
        throw e;
      }),
      shareReplay()
    );
  }

  deleteCoupon(dto: CouponDto): Observable<CouponDto> {
    const modalRef = this.modalService.openLoadingModal();
    return this.http.delete<Response<CouponDto>>("api/coupon/" + dto.code).pipe(
      tap(() => modalRef.close()),
      map(res => res.result),
      catchError(e => {
        modalRef.close();
        throw e;
      }),
      shareReplay()
    );
  }

  getOrders(page: number = 1, size: number = 10): Observable<PaginatedResponse<PlacedOrderDto[]>> {
    const modalRef = this.modalService.openLoadingModal();
    return this.http.get<PaginatedResponse<PlacedOrderDto[]>>("api/order", { params: { page, size, sort: "date,desc" } }).pipe(
      tap(() => modalRef.close()),
      catchError(e => {
        modalRef.close();
        throw e;
      }),
      shareReplay()
    );
  }

  updateOrder(dto: PlacedOrderDto): Observable<PlacedOrderDto> {
    const modalRef = this.modalService.openLoadingModal();
    return this.http.put<Response<PlacedOrderDto>>("api/order", dto).pipe(
      tap(() => modalRef.close()),
      map(res => res.result),
      catchError(e => {
        modalRef.close();
        throw e;
      }),
      shareReplay()
    );
  }

  deleteOrder(dto: PlacedOrderDto): Observable<PlacedOrderDto> {
    const modalRef = this.modalService.openLoadingModal();
    return this.http.delete<Response<PlacedOrderDto>>("api/order/" + dto.id).pipe(
      tap(() => modalRef.close()),
      map(res => res.result),
      catchError(e => {
        modalRef.close();
        throw e;
      }),
      shareReplay()
    );
  }

  getSubscriptions(): Observable<SubscriptionDto[]> {
    const modalRef = this.modalService.openLoadingModal();
    return this.http.get<Response<SubscriptionDto[]>>("api/subscription").pipe(
      tap(() => modalRef.close()),
      map(r => r.result),
      catchError(e => {
        modalRef.close();
        throw e;
      }),
      shareReplay()
    );
  }

}
