import { Component } from '@angular/core';
import { LoginDto } from "@frontend/data-access";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { SessionService } from "../../services/session.service";

@Component({
  selector: 'fokos-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  error: string | undefined;
  form = new FormGroup<Record<keyof LoginDto, FormControl>>({
    email: new FormControl<string>('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
  });

  constructor(private sessionService: SessionService,
              private router: Router) {
  }

  async login() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.sessionService.login(this.form.value as LoginDto).subscribe(
        () => this.router.navigate(['/']),
        e => this.error = e.error.errors
      );
      this.router.navigate(['/']);
    }
  }
}
