import { Component } from '@angular/core';
import { SubscriptionDto } from "@frontend/data-access";
import { AdminService } from "../../services/admin.service";
import { environment } from "../../../environments/environment";

@Component({
  selector: 'fokos-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss'],
})
export class SubscriptionsComponent {
  subscriptions: SubscriptionDto[] = [];

  constructor(private service: AdminService) {
    this.service.getSubscriptions().subscribe(res => this.subscriptions = res);
  }

  get emails() {
    return this.subscriptions.map(s => s.email).join(', ');
  }

}
