<div class="min-w-[800px] h-auto bg-grey py-6 px-12 rounded-lg relative">
  <div class="absolute top-4 right-4 cursor-pointer" (click)="close(false)">
    <img class="w-6 h-6" src="assets/icons/close-bold.svg"/>
  </div>
  <h3>#{{order.id}} - {{order.transactionId}} </h3>
  <div class="max-h-[60vh] overflow-auto my-6">
    <div class="grid grid-cols-2 items-center gap-2">
      <p>Megrendelés dátuma:</p>
      <p> {{order.date | date:'y. MM. d. HH:mm:ss' }}</p>
      <p>Fizetési mód:</p>
      <p> {{order.payMode | fokosPayMode}}</p>
      <p>Státusz:</p>
      <p> {{order.status | fokosStatus}}</p>
    </div>
    <div class="flex flex-col gap-1 mt-4">
      <span class="text-green font-bold">Számlázási adatok</span>
      <fokos-input><span fokosInputTitle>név</span><input [formControl]="invoiceControls.name"></fokos-input>
      <fokos-input><span fokosInputTitle>cég</span><input [formControl]="invoiceControls.company"></fokos-input>
      <fokos-input><span fokosInputTitle>adószám</span><input [formControl]="invoiceControls.taxNumber"></fokos-input>
      <fokos-input><span fokosInputTitle>irsz.</span><input [formControl]="invoiceControls.zip"></fokos-input>
      <fokos-input><span fokosInputTitle>város</span><input [formControl]="invoiceControls.city"></fokos-input>
      <fokos-input><span fokosInputTitle>cím</span><input [formControl]="invoiceControls.address"></fokos-input>
      <fokos-input><span fokosInputTitle>email</span><input [formControl]="invoiceControls.email"></fokos-input>
      <fokos-input><span fokosInputTitle>telefon</span><input [formControl]="invoiceControls.phone"></fokos-input>
      <fokos-input><span fokosInputTitle>megjegyzés</span><textarea [formControl]="invoiceControls.comment"></textarea>
      </fokos-input>
    </div>
    <div class="flex flex-col gap-1 mt-4">
      <span class="text-green font-bold">Szállítási adatok</span>
      <fokos-input><span fokosInputTitle>név</span><input [formControl]="shippingControls.name"></fokos-input>
      <fokos-input><span fokosInputTitle>cím</span><input [formControl]="shippingControls.address"></fokos-input>
      <fokos-input><span fokosInputTitle>email</span><input [formControl]="shippingControls.email"></fokos-input>
      <fokos-input><span fokosInputTitle>telefon</span><input [formControl]="shippingControls.phone"></fokos-input>
      <fokos-input><span fokosInputTitle>megjegyzés</span><textarea [formControl]="shippingControls.comment"></textarea>
      </fokos-input>
      <fokos-input><span fokosInputTitle>átvételi mód</span>
        <fokos-radio class="flex flex-col gap-2" [formControl]="shippingControls.delivery">
          <fokos-radio-item *ngFor="let mode of DeliveryModes"
                            [disabled]="mode.disabled"
                            [value]="mode">{{mode.deliveryMode | fokosDeliveryMode}}
            <ng-container *ngIf="mode.price > 0">({{mode.price |fokosCurrency}})</ng-container>
          </fokos-radio-item>
        </fokos-radio>
      </fokos-input>
    </div>
    <div class="flex flex-col gap-1 mt-4">
      <span class="text-green font-bold">kosár tartalma</span>
      <div class="grid grid-cols-2 items-center gap-2">
        <ng-container *ngFor="let formGroup of formGroup.controls.entities.controls">
          <ng-container [formGroup]="asFormGroup(formGroup)">
          <p>{{formGroup.value.gin.name}} ({{formGroup.value.gin.price | fokosCurrency}}/db)</p>
          <fokos-number-stepper formControlName="count" [min]="1"></fokos-number-stepper>
          </ng-container>
        </ng-container>
        <span class="text-sm text-green font-bold">kupon:</span>
        <span class="text-sm text-green font-bold">{{order.coupon?.code ?? '-'}}</span>

        <span class="text-sm text-green font-bold">mentett végösszeg:</span>
        <span class="text-sm text-green font-bold">{{order.totalPrice | fokosCurrency}}</span>

        <span class="text-sm text-green font-bold">aktuális végösszeg:</span>
        <span class="text-sm text-green font-bold">{{totalPrice | fokosCurrency}}</span>

        <span class="text-sm text-green font-bold">különbözet:</span>
        <span class="text-sm text-green font-bold">{{totalPrice - order.totalPrice  | fokosCurrency}}</span>
      </div>

    </div>
  </div>
  <div class="flex justify-between">
    <fokos-button (click)="close()">Mégse</fokos-button>
    <fokos-button (click)="save()">Mentés</fokos-button>
  </div>
</div>
