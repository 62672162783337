import { Injectable } from "@angular/core";
import { LoginDto, LoginResponse } from "@frontend/data-access";
import { HttpClient } from "@angular/common/http";
import { Observable, tap } from "rxjs";
import { SessionStore } from "../state/session/session.state";
import { Router } from "@angular/router";

@Injectable({providedIn: 'root'})
export class SessionService {

  constructor(private http: HttpClient,
              private router: Router,
              private store: SessionStore) {
  }

  login(dto: LoginDto): Observable<LoginResponse> {
    return this.http.post<LoginResponse>('api/admin/auth', dto).pipe(
      tap(res => this.store.update({token: res.token}))
    );
  }

  logout() {
    this.store.update({token: ''});
    this.router.navigate(['/bejelentkezes']);
  }
}
