import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { SessionQuery } from "../state/session/session.query";
import { SessionService } from "../services/session.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private sessionQuery: SessionQuery, private sessionService: SessionService) {
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const authToken = this.sessionQuery.token;
    if (!!authToken) {
      const authReq = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + authToken)
      });
      return next.handle(authReq).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401 || error.status === 403) {
            this.sessionService.logout();
          }
          return throwError(error);
        }));
    }
    return next.handle(req);
  }
}
