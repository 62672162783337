import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AboutDto } from "@frontend/data-access";
import { AdminService } from "../../services/admin.service";
// @ts-ignore
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { DataUrl, NgxImageCompressService, UploadResponse } from "ngx-image-compress";
@Component({
  selector: "fokos-about",
  templateUrl: "./about.component.html",
  styleUrls: ["./about.component.scss"]
})
export class AboutComponent implements OnInit {
  Editor = Editor;
  form: FormGroup<Record<keyof AboutDto, FormControl>> = new FormGroup({
      title: new FormControl("", Validators.required),
      description: new FormControl("", Validators.required),
      moreDescription: new FormControl(),
      image: new FormControl(''),
    }
  );

  constructor(private service: AdminService,
              private imageCompress: NgxImageCompressService) {
  }

  ngOnInit() {
    this.service.getAbout().subscribe(res => this.form.patchValue(res));
  }

  save() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.service.saveAbout(this.form.value as AboutDto).subscribe();
    }
  }
  uploadAndResize() {
    return this.imageCompress
      .uploadFile()
      .then(({ image }: UploadResponse) => {
        this.form.controls.image.setValue(image);
      });
  }
}
