<div class="content pt-10">
  <h2 class="mb-10">Üdvözlő szöveg</h2>
  <form class="flex flex-col gap-6">
    <fokos-input>
      <span fokosInputTitle>cím</span>
      <input [formControl]="form.controls.title" placeholder="...">
    </fokos-input>

    <fokos-input  [paddingOff]="true">
      <span fokosInputTitle>szöveg</span>
      <ckeditor [formControl]="form.controls.description" [editor]="Editor"></ckeditor>
    </fokos-input>
    <fokos-button type="submit" (click)="save()">Mentés</fokos-button>
  </form>
</div>
